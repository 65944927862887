
html {
    body {
        font-size: 1.0rem;
    }

    .ADMIN-ONLY,.OPERATOR-ONLY,.CUSTOMER-ONLY,.REVIEWER-ONLY {
        visibility: hidden;
    }

    body.ADMIN {
        .ADMIN-ONLY {
            visibility: visible;
        }
        .OPERATOR-ONLY {
            visibility: visible;
        }        
        .CUSTOMER-ONLY {
            display: none !important;
        }        
    }

    body.REVIEWER {
        .REVIEWER-ONLY {
            visibility: visible;
        }
        .OPERATOR-ONLY {
            visibility: visible;
        }        
        .CUSTOMER-ONLY {
            display: none !important;
        }        
    }

    body.OPERATOR {
        .ADMIN-ONLY {
            display: none !important;
        }
        .OPERATOR-ONLY {
            visibility: visible;
        }        
        .CUSTOMER-ONLY {
            display: none !important;
        }
    }

    body.CUSTOMER {
        .ADMIN-ONLY {
            display: none !important;
        }        
        .OPERATOR-ONLY {
            display: none !important;
        }        
        .CUSTOMER-ONLY {
            visibility: visible;
        }
    }    
}



.hidden {
    display: none !important;
}

.logo img {
    margin-top: 20px;
    height: 180px;
}

.card-title {
    font-size: 22px;
}

h5.card-title {
    font-size: 20px;
}

.custom-select-sm {
    font-size: 1.0rem;
}

.menu-title {
    font-size: 16px;
}

#sidebar-menu ul {
    li {
        a {
            font-size: 23px;
        }

        &.active {
            background: #fff;
            
            a {
                color: #031e4b;
            }
    
        }
    }
}

.form-control {
    font-size: 1.0rem;
}

select.form-control {
    padding-top: 4px;
}


.btn {
    font-size: 1.0rem;
}

.header-profile-user {
    border: 1px solid #9d9d9d;
    &.bx {
        font-size: 23px;
    }
}

.progress {
    font-size: 1.0rem;
    height: 1.5rem;
}

.file-selector:last-child {
    border-top: 1px dotted #ddd;
}

.file-selector:first-child {
    border-top: none !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fdfdfd;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #FFF;
}

.modal-footer {
    .btn {
        min-width: 80px;
    }
}

.modal-xxl {
    max-width: 80%;
    height: 95%;

    .modal-content {
        height: 100%;
    }
}

.page-item.active .page-link {
    min-width: 38px;
    text-align: center;
}

doc-viewer-dialog {
    .modal-body {
        background-color: gray;
        text-align: center;
        padding: 0;
    }
    .modal-dialog-scrollable .modal-body {
        overflow-y: auto;        
        text-align: center;
        border: 1px solid #CCC;
        padding: 1rem;
    }

    .modal-header {
        button {
            min-width: 90px;
        }

        .rejected {
            background-color: #ffe9e9;
            text-align: center;
            color: red;
            span:first-child {
                font-weight: 700;
            }
        }
    }
}

ivp-upload {
    border-top: 1px solid #c3c3c366;
    &:last-child {
        border-bottom: 1px solid #c3c3c366;
    }

    a {
        width: 100%;
    }

    .file-wrapper { 
        border:1px solid #cccccc91; background-color: white;padding:  3px; width: 100%; overflow:hidden;
    }

    .REJECTED {
        background: #ffe6cb;
    }

    .APPROVED {
        background: #cbffd0;

        .fa-check {
            font-size: 30px;
            color: #009b2b;
        }
    }

    .reject-notes {
        color: red;
        font-style: italic;
    }

    .reject-notes-customer-info {
        color: red;
        font-size: 14px;
    }

    .btnDelete {
        max-height: 40px;
    }

    .obbligatorio {
        color: red;        
    }
}
.input-group-text {
    font-size: 1.0rem;
}

.input-group.not-valid {

    .form-control, span {
        background-color: #ffb8b8;
        color: red;
        font-weight: bold;
    }
}

#basic-datatable_filter {
    .btnAdd {
        height: 32px;
    }
}

.EXCLUDED {
    display: none !important;
}

contractor-document-dialog {
    .btn-remove {
        position: absolute;
        right: 15px;
        top: 60px;
    }

    .document-container {
        background-color: #5d5d5d;
        overflow: auto;
        position: absolute;
        bottom: 0px;
        top: 85px;
        box-shadow: 0px 0px 4px #7d7d7d inset;
        padding: 0;



        .file-selector {
            background-color: #FFFFFF;
            height: 60px;
            padding: 20px;
        }
    }
}

.overflow-hidden {
    overflow: hidden !important;
}

.add-sal {
    text-align: center;
    padding: 20px;
    background: white;
    margin: 10px auto;
    border: 1px solid rgba(204, 204, 204, 0.568627451);
}